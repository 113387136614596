import React from "react";
import {
    Box,
    ColumnLayout,
    Container,
    Link,
    Form,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import {
    FremontButton,
    FremontCheckbox,
    FremontInput,
    FremontSelect,
    FremontTextarea
} from "utils/CommonComponents";
import Constants from "utils/Constants";
import FremontComponentInfo from "common/FremontComponentInfo";
import HelperFunctions from "common/HelperFunctions";

const displayOrderButtons = (props) => {
    if (props.isOrderCompleted) {
        return <span/>;
    }
    if (props.isUpdateOrderInfoEditClicked) {
        return (
            <SpaceBetween direction="horizontal" size="xs">
                <FremontButton
                    disabled={props.isUpdateOrderInfoInProgress}
                    variant="normal"
                    onClick={props.handleUpdateOrderEdit}
                >
                    Cancel
                </FremontButton>
                <FremontButton
                    id="submitOrderInfoButton"
                    loading={props.isUpdateOrderInfoInProgress}
                    variant="primary"
                    onClick={props.handleSubmitUpdateOrderInfo}
                >
                    {props.isUpdateOrderInfoInProgress ? "Submitting..." : "Submit"}
                </FremontButton>
            </SpaceBetween>
        );
    }
    return (
        <FremontButton
            id="editOrderInfoButton"
            iconName="edit"
            variant="primary"
            onClick={props.handleUpdateOrderEdit}
            disabled={props.isAnyEditModeEnabled}
        />
    );
};

/**
 * This component serves as the main container holding all of the other components in the order information tab
 */
const OrderInformation = props => (
    <Container
        header={
            <Header
                variant="h2"
                actions={
                    displayOrderButtons(props)
                }
            >
                Order Information
            </Header>
        }
    >
        <Container
            header={
                <Header
                    variant="h2"
                >
                    General Information
                </Header>
            }
        >
            <Form>
                <ColumnLayout columns={2} borders="vertical">
                    <div>
                        <Box color="text-label">Service Type:</Box>
                        <div>
                            {HelperFunctions.getOrderServiceTypeCustomerFabricString(props.order)}
                        </div>
                    </div>
                    <div>
                        <Box color="text-label">Order Type:</Box>
                        <div>
                            {Constants.BACKEND_TO_FRONTEND_MAP[props.order[Constants.ATTRIBUTES.orderType]]}
                        </div>
                    </div>
                    <div>
                        <Box color="text-label">Provider:</Box>
                        <div>
                            <Link
                                href={`${Constants.ROUTES.provider}/${
                                    props.order[Constants.ATTRIBUTES.providerName]
                                }`}
                            >
                                {props.order[Constants.ATTRIBUTES.providerName]}
                            </Link>
                        </div>
                    </div>
                    <div>
                        <Box color="text-label">Business Need:</Box>
                        <div>{props.order[Constants.ATTRIBUTES.businessNeed]}</div>
                    </div>
                    {props.order[Constants.ATTRIBUTES.peeringDBInternetExchangeName] &&
                    <div>
                        <Box color="text-label">Peering DB IX Name:</Box>
                        <div>{props.order[Constants.ATTRIBUTES.peeringDBInternetExchangeName]}</div>
                    </div>
                    }
                    {props.order[Constants.ATTRIBUTES.installAndDecommission] &&
                    <div>
                        <Box color="text-label">Related Order ID:</Box>
                        <div>
                            {(props.order[Constants.ATTRIBUTES.relatedOrderIdList]
                                && props.order[Constants.ATTRIBUTES.relatedOrderIdList].length > 0) ?
                                props.order[Constants.ATTRIBUTES.relatedOrderIdList].map(orderId => (
                                    <Link href={`${Constants.ROUTES.order}/${orderId}`} key={orderId}>
                                        {`${orderId} `}
                                    </Link>
                                )) : "-"}
                        </div>
                    </div>
                    }
                    <div>
                        <Box color="text-label">Owner:</Box>
                        {props.isUpdateOrderInfoEditClicked && props.isUserManager ?
                            <FremontSelect
                                id={Constants.ATTRIBUTES.ownerId}
                                placeholder={props.allFieldsDisabled ? "" : "Select an Owner"}
                                statusType={props.loadingResources ? Constants.LOADING : Constants.FINISHED}
                                loadingText={Constants.STATUS_TEXTS.loadingComponents}
                                options={HelperFunctions.createSelectedOptions(props.bizDevOptions)}
                                selectedOption={HelperFunctions.createSelectedOption(
                                    props.updatedOrder[Constants.ATTRIBUTES.ownerId]
                                )}
                                empty="No Business Developers found."
                                disabled={props.isUpdateOrderInfoInProgress}
                                onChange={props.handleOrderInputChange}
                                filteringType="auto"
                                noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                            />
                            :
                            <div>{props.order[Constants.ATTRIBUTES.ownerId]}</div>}
                    </div>
                    {props.order.orderType === Constants.ORDER_TYPES.DECOMMISSION &&
                    props.order.serviceType !== Constants.SERVICE_TYPES.SFP &&
                    <div>
                        <Box color="text-label">Business Operations:</Box>
                        {props.isUpdateOrderInfoEditClicked && props.isUserManager ?
                            <FremontSelect
                                id={Constants.ATTRIBUTES.businessOperationsId}
                                placeholder={props.allFieldsDisabled ? "" : "Select a Biz Ops Resource"}
                                statusType={props.loadingResources ? Constants.LOADING : Constants.FINISHED}
                                loadingText={Constants.STATUS_TEXTS.loadingComponents}
                                options={HelperFunctions.createSelectedOptions(props.bizOpsResources)}
                                selectedOption={HelperFunctions.createSelectedOption(
                                    props.updatedOrder[Constants.ATTRIBUTES.businessOperationsId]
                                )}
                                empty="No Business Operation Resources found."
                                disabled={props.isUpdateOrderInfoInProgress}
                                onChange={props.handleOrderInputChange}
                                filteringType="auto"
                                noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                            />
                            :
                            <div>{props.order[Constants.ATTRIBUTES.businessOperationsId] || "-"}</div>
                        }
                    </div>
                    }
                    {(Constants.INTERCONNECT_SERVICE_TYPES.includes(props.order[Constants.ATTRIBUTES.serviceType])
                        || Constants.PATH_CUSTOMER_FABRICS.includes(
                            props.order[Constants.ATTRIBUTES.customerFabric]
                        )) &&
                        <div>
                            <Box color="text-label">Engineer:</Box>
                            {props.isUpdateOrderInfoEditClicked && props.isUserManager ?
                                <FremontSelect
                                    id={Constants.ATTRIBUTES.engineerId}
                                    placeholder={props.allFieldsDisabled ? "" : "Select an Engineer Resource"}
                                    statusType={props.loadingResources ? Constants.LOADING : Constants.FINISHED}
                                    loadingText={Constants.STATUS_TEXTS.loadingComponents}
                                    options={HelperFunctions.createSelectedOptions(props.engineerOptions)}
                                    selectedOption={HelperFunctions.createSelectedOption(
                                        props.updatedOrder[Constants.ATTRIBUTES.engineerId]
                                    )}
                                    empty="No Engineer Resources found."
                                    disabled={props.isUpdateOrderInfoInProgress}
                                    onChange={props.handleOrderInputChange}
                                    filteringType="auto"
                                    noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                                />
                                :
                                <div>{props.order[Constants.ATTRIBUTES.engineerId]}</div>
                            }
                        </div>
                    }
                    {HelperFunctions.isOrderOpticalInstallPathOrder(props.order) &&
                        <div>
                            <Box color="text-label">Congo Required:</Box>
                            {props.isUpdateOrderInfoEditClicked ?
                                <FremontCheckbox
                                    id={Constants.ATTRIBUTES.congoRequired}
                                    checked={props.updatedOrder.congoRequired}
                                    onChange={props.handleOrderInputChange}
                                />
                                :
                                <div>
                                    {props.order[Constants.ATTRIBUTES.congoRequired] ? Constants.YES_NO.yes
                                        : Constants.YES_NO.no}
                                </div>
                            }
                        </div>
                    }
                    {props.order[Constants.ATTRIBUTES.opticalEngineerId] &&
                        <div>
                            <Box color="text-label">Optical Engineer ID:</Box>
                            {props.isUpdateOrderInfoEditClicked ?
                                <FremontInput
                                    id={Constants.ATTRIBUTES.opticalEngineerId}
                                    disabled={props.isUpdateOrderInfoInProgress}
                                    value={props.updatedOrder[Constants.ATTRIBUTES.opticalEngineerId]}
                                    onInput={props.handleOrderInputChange}
                                />
                                :
                                <div>{props.order[Constants.ATTRIBUTES.opticalEngineerId] || "-"}</div>
                            }
                        </div>
                    }
                    {props.order.orderType !== Constants.ORDER_TYPES.DECOMMISSION &&
                    <div>
                        <Box color="text-label">Provisioner:</Box>
                        {props.isUpdateOrderInfoEditClicked && props.isUserManager ?
                            <FremontSelect
                                id={Constants.ATTRIBUTES.provisionerId}
                                placeholder={props.allFieldsDisabled ? "" : "Select a Provisioner Resource"}
                                statusType={props.loadingResources ? Constants.LOADING : Constants.FINISHED}
                                loadingText={Constants.STATUS_TEXTS.loadingComponents}
                                options={HelperFunctions.createSelectedOptions(props.provisionerOptions)}
                                selectedOption={HelperFunctions.createSelectedOption(
                                    props.updatedOrder[Constants.ATTRIBUTES.provisionerId]
                                )}
                                empty="No Provisioner Resources found."
                                disabled={props.isUpdateOrderInfoInProgress}
                                onChange={props.handleOrderInputChange}
                                filteringType="auto"
                                noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                            />
                            :
                            <div>{props.order[Constants.ATTRIBUTES.provisionerId] || "-"}</div>
                        }
                    </div>
                    }
                    {props.order.orderType !== Constants.ORDER_TYPES.CHANGE &&
                    <div>
                        <Box color="text-label">Provider Contact:</Box>
                        {props.isUpdateOrderInfoEditClicked ?
                            <FremontSelect
                                id={Constants.ATTRIBUTES.contactId}
                                statusType={props.loadingResources ? Constants.LOADING : Constants.FINISHED}
                                loadingText={Constants.STATUS_TEXTS.loadingContacts}
                                options={props.contactOptions}
                                selectedOption={props.contactOptions.find(contactOption =>
                                    contactOption.value === props.updatedOrder.contactId)}
                                empty="No contacts found."
                                disabled={props.isUpdateOrderInfoInProgress}
                                onChange={props.handleOrderInputChange}
                                filteringType="auto"
                                noMatch={Constants.SELECT_FILTERING_NO_MATCH_MESSAGE}
                            />
                            :
                            <div>
                                <Link
                                    href={`${Constants.ROUTES.contact}/${
                                        props.order[Constants.ATTRIBUTES.contactId]
                                    }`}
                                >
                                    {props.orderContact}
                                </Link>
                            </div>
                        }
                    </div>
                    }
                    {props.order.orderType !== Constants.ORDER_TYPES.CHANGE &&
                    <div>
                        <Box color="text-label">Required Completion Date:</Box>
                        <div>{props.order[Constants.ATTRIBUTES.requiredCompletionDate]}</div>
                    </div>
                    }
                    {!!props.order.stageSlaId &&
                    <div>
                        <Box color="text-label">Order ETA:</Box>
                        <div>{props.order[Constants.ATTRIBUTES.orderEta] || "-"}</div>
                    </div>
                    }
                    <div>
                        <Box color="text-label">Description:</Box>
                        {props.isUpdateOrderInfoEditClicked ?
                            <FremontTextarea
                                id={Constants.ATTRIBUTES.description}
                                disabled={props.isUpdateOrderInfoInProgress}
                                value={props.updatedOrder[Constants.ATTRIBUTES.description]}
                                onInput={props.handleOrderInputChange}
                            />
                            :
                            <div className="pre-wrap">
                                {props.order[Constants.ATTRIBUTES.description] || "-"}
                            </div>}
                    </div>
                </ColumnLayout>
            </Form>
        </Container>
        <FremontComponentInfo
            modifiedBy={props.order[Constants.ATTRIBUTES.modifiedBy]}
            modifiedTime={props.order[Constants.ATTRIBUTES.modifiedTime]}
            createdBy={props.order[Constants.ATTRIBUTES.createdBy]}
            createdTime={props.order[Constants.ATTRIBUTES.createdTime]}
        />
    </Container>
);

export default OrderInformation;