import moment from "moment";
import Constants from "../utils/Constants";
import {
    getClientToClientTableColumns,
    getDownloadAbleClientToClientTableColumns,
    getDownloadAbleMuxToMuxTableColumns,
    getDownloadAblePassiveToPassiveTableColumns,
    getDownloadAbleRouterToRouterTableColumns,
    getDownloadAbleTrunkToTrunkTableColumns,
    getEncryptionToDWDMColumns,
    getEncryptionToEncryptionColumns,
    getMuxToMuxTableColumns,
    getOduToOduTableColumns,
    getOmsToOmsTableColumns,
    getParallelContainerColumns,
    getPassiveToPassiveTableColumns,
    getPowerMuxToPowerMuxTableColumns,
    getProtectionContainerColumns,
    getRouterToDwdmTableColumns,
    getRouterToEncryptionColumns,
    getRouterToRouterTableColumns,
    getTrunkToTrunkTableColumns,
    getIntraDcRouterToIntraDcRouterColumns
} from "../link/LinkColumnDefinitions";
import DevEnvironment from "../../../common/DevEnvironment";

const UUID_REGEX = /([a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12})/;

export default class HelperFunctions {
    static getUUID = (str) => {
        const match = str.match(UUID_REGEX);
        return match ? match[1] : null;
    };
    /*
     * This function displays an error message on the flashbar pertaining to what happened if the server call
     * returned an error, and handles setting additional states.
     * NOTE: there is a "flashbar" state on every major page of the app that this function interacts with.
     */
    static displayFlashbarError = (reactComponent, error, additionalSetStateFields) => {
        reactComponent.setState(Object.assign({}, {
            flashbar: {
                type: Constants.FLASHBAR_TYPES.error,
                text: error.message
            }
        },
        additionalSetStateFields));
        window.scrollTo(0, 0);
    };

    /*
     * This function displays a success message on the flashbar for a successful backend call.
     * NOTE: there is a "flashbar" state on every major page of the app that this function interacts with.
     */
    static displayFlashbarSuccess = (reactComponent, successText, additionalSetStateFields) => {
        reactComponent.setState(Object.assign({}, {
            flashbar: {
                type: Constants.FLASHBAR_TYPES.success,
                text: successText
            }
        },
        additionalSetStateFields));
        window.scrollTo(0, 0);
    };

    /**
     * This function is used for used for handling the flashbar messages from child tabs
     */
    static handleFlashBarMessagesFromChildTabs = (reactComponent, flashbarSuccessText, error, dismiss) => {
        if (flashbarSuccessText) {
            HelperFunctions.displayFlashbarSuccess(reactComponent, flashbarSuccessText);
        }
        if (error) {
            HelperFunctions.displayFlashbarError(reactComponent, error);
        }
        if (dismiss) {
            HelperFunctions.dismissFlashbar(reactComponent);
        }
    };

    /**
     * This function clears the flashbar on a page.
     */
    static dismissFlashbar = (reactComponent, additionalSetStateFields) => {
        reactComponent.setState(Object.assign({}, {
            flashbar: {
                type: "",
                text: ""
            }
        },
        additionalSetStateFields));
    };

    static isDevelopmentStack = () =>
        window.location.host.includes(DevEnvironment.YOUR_ALIAS) || window.location.host.includes("localhost");

    static isGamma = () => window.location.host.includes("gamma");

    // isProd should return true for Prod as well Gamma (This is for testing in Gamma Environment when leveraging
    // on feature flag)
    static isProd = () => window.location.host.includes("prod") || window.location.host.includes("gamma");
    static isGlobalProd = () => window.location.host.includes("prod.");

    static userInPosixGroup = (user, posixGroup) => !!user
        && !!user.permissions
        && user.permissions[posixGroup] !== undefined
        && user.permissions[posixGroup];

    static filterNativeLinks = (linksInLinkTypeMap) => {
        // Filter out all the RouterToRouter links in our LinkTypeMap that do not have any link_type attribute
        const filteredLinksInLinkTypeMap = {};

        // Check to ensure the input is valid
        if (!linksInLinkTypeMap || !(typeof linksInLinkTypeMap === "object")) {
            return filteredLinksInLinkTypeMap;
        }

        Object.entries(linksInLinkTypeMap).forEach((entry) => {
            const [key, value] = entry;

            if (key === Constants.LINK_TYPES.routerToRouter) {
                if (value && Array.isArray(value)) {
                    filteredLinksInLinkTypeMap[key] = value.filter(link =>
                        link.attributes.some(attribute => attribute.key === Constants.ATTRIBUTES.linkType));
                }
            } else {
                filteredLinksInLinkTypeMap[key] = value;
            }
        });
        return filteredLinksInLinkTypeMap;
    }

    /**
     * Comparator for sorting ids that have numbers in it. For example, Sorting ['Order-9', 'Order-10'] based on the
     * number value correctly instead of alphabetically. Subtracting works because sorting really just cares about
     * a positive or negative value (since we are working with numbers here).
     */
    static sortIdsNumerically = (a, b, fieldName) => {
        // We need to account for the cases where the field is undefined in a, b, or both
        if (!a[fieldName] && !!b[fieldName]) return -1;
        if (!!a[fieldName] && !b[fieldName]) return 1;
        if (!a[fieldName] && !b[fieldName]) return 0;
        return a[fieldName].match(/\d+$/)[0] - b[fieldName].match(/\d+$/)[0];
    }

    /**
     * Sorts an array of objects based on a field. This is going to work only on first level fields of the object.
     */
    static sortObjectsByField = (objectArray, field, desc = false) => (objectArray.sort((a, b) => {
        if (a[field] > b[field]) {
            return desc ? -1 : 1;
        } else if (a[field] < b[field]) {
            return desc ? 1 : -1;
        }
        return 0;
    }));

    /**
     * Checks if two lists are equal irrespective of their order
     * @param a first list
     * @param b second list
     * @returns {boolean} returns true if they have same values and false otherwise
     * Taken from https://www.30secondsofcode.org/articles/s/javascript-array-comparison
     */
    static equalsIgnoreOrder = (a, b) => {
        if (a.length !== b.length) return false;
        const uniqueValues = new Set([...a, ...b]);
        const checkItemCounts = [];
        uniqueValues.forEach((v) => {
            const aCount = a.filter(e => e === v).length;
            const bCount = b.filter(e => e === v).length;
            checkItemCounts.push(aCount === bCount);
        });

        return checkItemCounts.filter(check => !check).length <= 0;
    }

    /**
     * Checks if the input object is empty: {} or undefined.
     * @param object object to test
     * @returns {boolean}
     */
    static isEmpty = object => !object || Object.keys(object).length === 0;


    // Use at your own risk. Guidelines on potentially when not to use here: https://stackoverflow.com/a/122704/4392915
    static deepClone = obj => (obj ? JSON.parse(JSON.stringify(obj)) : obj);

    /*
    * Creates a label with the pattern { label: <input>, value: <input> } from an input string. Used to display
    * selected values to the user
    */
    static createSelectedOption = input => (!input ? "" : { label: input, value: input });

    /**
     * Sort a list of file items in descending numerical file
     */
    static sortFiles = (files) => {
        if (files.length > 0) {
            files.sort((a, b) => (a.createdTime > b.createdTime ? -1 : 1));
        }
        return files;
    };

    /*
     * This function takes a list of strings and returns a list of options in the correct format for the Polaris
     * select component
     */
    static createSelectedOptions = input => input.map(option => HelperFunctions.createSelectedOption(option));

    static getLinkHierarchyOrder = (editable = false) => ({
        [Constants.LINK_TYPES.passiveToPassive]: getPassiveToPassiveTableColumns(editable),
        [Constants.LINK_TYPES.muxToMux]: getMuxToMuxTableColumns(editable),
        [Constants.LINK_TYPES.omsToOms]: getOmsToOmsTableColumns(editable),
        [Constants.CONTAINER_TYPES.protectionContainer]: getProtectionContainerColumns(editable),
        [Constants.LINK_TYPES.powerMuxToPowerMux]: getPowerMuxToPowerMuxTableColumns(editable),
        [Constants.LINK_TYPES.trunkToTrunk]: getTrunkToTrunkTableColumns(editable),
        [Constants.CONTAINER_TYPES.parallelContainer]: getParallelContainerColumns(editable),
        [Constants.LINK_TYPES.oduToOdu]: getOduToOduTableColumns(editable),
        [Constants.LINK_TYPES.routerToDWDM]: getRouterToDwdmTableColumns(editable),
        [Constants.LINK_TYPES.routerToRouter]: getRouterToRouterTableColumns(editable),
        [Constants.LINK_TYPES.clientToClient]: getClientToClientTableColumns(editable),
        [Constants.LINK_TYPES.encryptionToEncryption]: getEncryptionToEncryptionColumns(editable),
        [Constants.LINK_TYPES.encryptionToDWDM]: getEncryptionToDWDMColumns(editable),
        [Constants.LINK_TYPES.routerToEncryption]: getRouterToEncryptionColumns(editable),
        [Constants.LINK_TYPES.intraDcRouterToIntraDcRouter]: getIntraDcRouterToIntraDcRouterColumns(editable)
    });
    static getDownloadableLinkColumns = ((linkType) => {
        if (linkType === "Passive To Passive") {
            return getDownloadAblePassiveToPassiveTableColumns();
        } else if (linkType === "Mux To Mux") {
            return getDownloadAbleMuxToMuxTableColumns();
        } else if (linkType === "Trunk To Trunk") {
            return getDownloadAbleTrunkToTrunkTableColumns();
        } else if (linkType === "Router To Router") {
            return getDownloadAbleRouterToRouterTableColumns();
        } else if (linkType === "Client To Client") {
            return getDownloadAbleClientToClientTableColumns();
        }
        return null;
    });

    static createSelectedOptionForAttachment = attachmentType => ({
        value: attachmentType, label: Constants.CUTSHEET_TYPE_LABELS[attachmentType]
    });

    static createSelectedOptionsForAttachments = (attachmentTypes, user) => {
        const attachmentTypesToReturn = attachmentTypes
            .map(attachmentType => HelperFunctions.createSelectedOptionForAttachment(attachmentType));
        if (HelperFunctions.userInPosixGroup(user, Constants.POSIX_GROUPS.NEST)) {
            attachmentTypesToReturn.push(HelperFunctions.createSelectedOptionForAttachment(Constants.FIBER_KEY));
            attachmentTypesToReturn.push(HelperFunctions.createSelectedOptionForAttachment(Constants.FIBER_AUDIT_KEY));
        }
        return attachmentTypesToReturn;
    };


    static parseInt = str => (str ? parseInt(str, 10) : 0);

    static generateErrorMessageForFlashbar = (header, content, dismissible = false) => (
        [{
            type: Constants.FLASHBAR_TYPES.error,
            header,
            content,
            dismissible
        }]
    );

    static generateWarningMessageForFlashbar = (header, content, dismissible = false) => (
        [{
            type: Constants.FLASHBAR_TYPES.warning,
            header,
            content,
            dismissible
        }]
    );

    static generateSuccessMessageForFlashbar = (header, content, dismissible = false) => (
        [{
            type: Constants.FLASHBAR_TYPES.success,
            header,
            content,
            dismissible
        }]
    );

    /**
     * Helper methods to generate options for Select and Multiselect components.
     * If the labelFieldName is not defined, it will use the same field as the valueFieldName
     * @param items
     * @param valueFieldName
     * @param labelFieldName
     */
    static polarisOptionByField = (items, valueFieldName, labelFieldName) => {
        const labelFieldNameToUse = labelFieldName || valueFieldName;
        return items.map(item => ({
            [Constants.POLARIS_UTILS_OPTIONS.VALUE_KEY]: item[valueFieldName],
            [Constants.POLARIS_UTILS_OPTIONS.LABEL_KEY]: item[labelFieldNameToUse]
        }));
    }

    static formatAppliedEncryption = appliedEncryptionObject => `{Key Hash: ${appliedEncryptionObject.keyHash}, Last Rotation Time:${moment.unix(appliedEncryptionObject.lastRotationTime).utc().format(Constants.DATE_TIME_FORMAT)}}`

    static createKeyValueListFromObject = (obj, keys) => {
        const keyValueObjects = [];
        keys.forEach((objKey) => {
            if (obj[objKey] !== undefined) {
                if (objKey === Constants.LINK_DETAIL_FIELDS_MAP.appliedEncryption) {
                    keyValueObjects.push({
                        key: objKey,
                        value: HelperFunctions.formatAppliedEncryption(obj[objKey])
                    });
                } else {
                    keyValueObjects.push({ key: objKey, value: obj[objKey] });
                }
            }
        });
        return keyValueObjects;
    }

    /**
     * Extract the UUID from a linkInstance id
     * @param link
     * @returns uuid
     */
    static getIdentifierFromLinkInstance = (link) => {
        const match = link.match(Constants.UUID_REGEX);
        return match ? match[0] : null;
    }

    /**
     * Ensure elements in the list are unique
     * @param list
     * @returns {any[]}
     */
    static unique = list => Array.from(new Set(list));

    /**
     * This will split a list into chunks of a given size, and return back a list of those chunks.
     * @param list
     * @param chunkSize
     */
    static chunkifyList = (list, chunkSize) => {
        // To prevent infinite loop
        if (chunkSize === 0) {
            return [];
        }

        const chunkifiedList = [];
        for (let i = 0; i < list.length; i += chunkSize) {
            chunkifiedList.push(list.slice(i, i + chunkSize));
        }

        return chunkifiedList;
    }

    static callWithDelay = (func, delayInSeconds) => {
        setTimeout(() => {
            func();
        }, delayInSeconds * 1000);
    };

    static getValueFromRecordAttributes =
        (item, key) => item.attributesToDisplay.find(att => att.key === key)?.value || "";

    static extractRecordAttributes = item => item.attributesToDisplay
        ?.reduce((res, att) => {
            res[att.key] = att.value;
            return res;
        }, {});

    static sortNumerically = (a, b, key) => (new Intl.Collator([], { numeric: true })).compare(a[key], b[key]);

    static sortByAttributeValues = (a, b, attribute) => {
        const value1 = HelperFunctions.getValueFromRecordAttributes(a, attribute);
        const value2 = HelperFunctions.getValueFromRecordAttributes(b, attribute);
        return (new Intl.Collator([], { numeric: true })).compare(value1, value2);
    }

    static sortDynamicCellValues = func => (a, b) => {
        const value1 = func(a);
        const value2 = func(b);
        return (new Intl.Collator([], { numeric: true })).compare(value1, value2);
    };

    // Utility function so that we can mock the click action
    static clickDownload = (link) => {
        link.click();
    }
    static getValuesFromDownloadableColumns
        = (item, linkColumns) => linkColumns.map((column) => {
            if (column.downloadableColumnHeader !== undefined) {
                if (column.downloadableValue(item) !== undefined) {
                    // eslint-disable-next-line no-useless-escape
                    return `\"${column.downloadableValue(item)}\"`;
                }
                return " ";
            }
            return null;
        });

    static getDownloadableColumnsHeader
        = (item, linkColumns) => linkColumns.map((header) => {
            if (header.downloadableColumnHeader !== undefined) {
                return header.downloadableColumnHeader;
            }
            return null;
        });

    static filterNullValuesFromArray = array => array.filter(item => item !== null);

    static downloadTableAsCutsheet(items, downloadableColumnDefinitions) {
        const rows = items.map(item => HelperFunctions.getValuesFromDownloadableColumns(
            item, downloadableColumnDefinitions
        ));
        const columnHeaders = HelperFunctions.getDownloadableColumnsHeader(items[0], downloadableColumnDefinitions);
        const filteredColumnHeaders = HelperFunctions.filterNullValuesFromArray(columnHeaders);
        const filteredRows = rows.map(row => HelperFunctions.filterNullValuesFromArray(row));
        const csvContent = [filteredColumnHeaders].concat(filteredRows).join("\n");
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8," });
        const objUrl = window.URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.setAttribute("href", objUrl);
        downloadLink.setAttribute("download", "cutsheet.csv");

        document.body.appendChild(downloadLink);
        HelperFunctions.clickDownload(downloadLink);
        document.body.removeChild(downloadLink);
    }

    static getIspTableDownloadableColumnDefinitions(contentDisplay, columnDefinition) {
        // We filter all the columns that have their visibilty toggled as true, by the user in preferences.
        const userOrderedVisibleColumns = contentDisplay.filter(object => object.visible).map(object => object.id);
        const visibleColumnDefinitions = columnDefinition.filter(
            column => userOrderedVisibleColumns.includes(column.id)
        );
        // Based on the preference of order by the user we sort the visbleColumnDefinitions
        return visibleColumnDefinitions.sort((a, b) =>
            userOrderedVisibleColumns.indexOf(a.id) - userOrderedVisibleColumns.indexOf(b.id));
    }

    static getHiddenColumnsCookie = (tableName) => {
        const index = document.cookie.indexOf(`${tableName}=`);

        if (index !== -1) {
            const stringValue = document.cookie.split(`${tableName}=`).pop().split(";")[0];
            return stringValue;
        }
        return 0;
    }

    static setHiddenColumnsCookie = (tableName, value) => {
        document.cookie = `${tableName}=${value}`;
        return true;
    }

    static getUuidFromFullLinkId = linkId => linkId.split(".")[4];

    static createFullLinkInstanceId = (instanceId) => {
        if (!instanceId) {
            return null;
        }

        if (instanceId.startsWith(Constants.LINK_INSTANCE_ID_PATTERN)) {
            return instanceId;
        }

        return Constants.LINK_INSTANCE_ID_PATTERN + instanceId;
    }

    static getSiteNameFromPort = (port) => {
        const matches = port.match(/([A-Za-z]{3})(\d+)/);
        if (!matches) {
            return null;
        }

        return matches[1].toUpperCase() + matches[2].padStart(3, "0");
    }

    // R2R can have multiple regex pattern and parsing logic is different
    // iad55-br* , pdx4-178*
    static getSiteNameFromHostname = (hostname) => {
        const matches = hostname.match(/((^[A-Za-z]{3})[a-zA-Z0-9]*)-((\d+)|([A-Za-z]+))/);
        if (!matches) {
            return null;
        }
        if (matches[4]) {
            // pdx4-178 Case
            return matches[2].toUpperCase() + matches[4].slice(0, 3).padStart(3, "0");
        }
        // iad55-br* case
        return HelperFunctions.getSiteNameFromPort(matches[1]);
    }
}
