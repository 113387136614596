export default class DevEnvironment {
    static YOUR_USER_POOL_ID = "us-west-2_j3SVoxoRf";

    static YOUR_CLIENT_ID = "50q9op6q6tgb99qshgrg0p9ud";

    static YOUR_ALIAS = "nongmei";

    static APP_WEB_DOMAIN = `fremont-${DevEnvironment.YOUR_ALIAS}-test-userpool.auth.us-west-2.amazoncognito.com`;

    static UI_API_GATEWAY_ENDPOINT = `https://${DevEnvironment.YOUR_ALIAS}.test.ui.fremont.networking.aws.a2z.com`;

    static OPERATIONS_API_GATEWAY_ENDPOINT = `https://${DevEnvironment.YOUR_ALIAS}.test.operations.fremont.networking.aws.a2z.com`;
}
