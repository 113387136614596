import React from "react";
import Constants from "../utils/Constants";

export default class HelperFunctions {
    /**
     * This function clears the flashbar on a page.
     */
    static dismissFlashbar = (reactComponent, additionalSetStateFields) => {
        reactComponent.setState(Object.assign({}, {
            flashbar: {
                type: "",
                text: ""
            }
        },
        additionalSetStateFields));
    };

    static isProd = () => window.location.host.includes("prod") || window.location.host.includes("gamma");

    static isValidDuplexPort = (duplexPort) => {
        const [start, end] = duplexPort.split("-").map(Number);
        return (
            !Number.isNaN(start) &&
            !Number.isNaN(end) &&
            start % 2 === 1 &&
            start + 1 === end
        );
    };

    static isInValidInteger = (value) => {
        if (value.trim() === "") {
            return "Field is empty";
        }

        // Should be an integer
        const number = Number(value);
        if (Number.isNaN(number)) {
            return "Input should be an integer";
        }

        if (number < 1) {
            return "Input should be > 0";
        }
        return undefined;
    }
    static getHiddenColumnsCookie = (tableName) => {
        const index = document.cookie.indexOf(`${tableName}=`);

        if (index !== -1) {
            const stringValue = document.cookie.split(`${tableName}=`).pop().split(";")[0];
            return stringValue;
        }
        return 0;
    }

    static setHiddenColumnsCookie = (tableName, value) => {
        document.cookie = `${tableName}=${value}`;
        return true;
    }

    static generateSuccessMessageForFlashbar = (header, content, dismissible = true, component) => (
        [{
            type: Constants.FLASHBAR_TYPES.success,
            header,
            content,
            dismissible,
            onDismiss: () => HelperFunctions.dismissFlashbar(component, { notificationToDisplay: [] })
        }]
    );

    static generateErrorMessageForFlashbar = (header, content, dismissible = true, component) => (
        [{
            type: Constants.FLASHBAR_TYPES.error,
            header,
            content,
            dismissible,
            onDismiss: () => HelperFunctions.dismissFlashbar(component, { notificationToDisplay: [] })
        }]
    );

    static toCapitalCamelCase = (input) => {
        if (input === null) {
            return "";
        }

        return input
            .split("_")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join("");
    };

    static createAttribute = (key, value) => ({
        key,
        value: {
            type: "String",
            stringValue: value
        }
    });

    static ensureValueNotNull = value =>
        // there is a bug in Polaris which cannot handle null values for autosuggest
        (!value ? "" : value)

    static provideColoredHeader = (inputText, index) => (
        <div style={{ background: index % 2 === 0 ? "#B0C4DE" : "#ADD8E6" }}>
            {inputText}
        </div>
    );

    static getConsumerUUID = (row, cutsheetType) => (
        cutsheetType === Constants.ISP_CUTSHEET_TYPE.LINE ?
            row.mux_to_mux_link_instance_id :
            row.router_to_router_link_instance_id
    );

    static sortHostname = (a, b) => {
        const splitA = a.split("-");
        const splitB = b.split("-");

        // Extract the numeric part (e.g., r10 and r2)
        const numericPartA = splitA[splitA.length - 1].replace(/^r/, "");
        const numericPartB = splitB[splitB.length - 1].replace(/^r/, "");

        // Compare the numeric parts as integers
        const numericComparison = parseInt(numericPartA, 10) - parseInt(numericPartB, 10);

        if (numericComparison !== 0) {
            return numericComparison;
        }

        // If the numeric parts are the same, compare the full strings
        return a.localeCompare(b);
    }

    static extractPortFromOsp = (ospPortString) => {
        // Use a regular expression to validate the format
        const formatRegex = /^\d+-\d+$/;

        if (formatRegex.test(ospPortString)) {
            // Split the input string by the hyphen ('-') and take the first part
            const parts = ospPortString.split("-");
            const extractedValue = parts[0];

            // Use parseInt to convert the extracted value to an integer
            return parseInt(extractedValue, 10);
        }
        // Input doesn't match the expected format
        return null;
    }

    static incrementAndFormat = (inputInteger) => {
        // Increment the input integer by 1
        const incrementedValue = inputInteger;

        // Convert the incremented value to a string and format it with a hyphen
        return `${incrementedValue}-${incrementedValue + 1}`;
    }

    static massUpdatePorts = (newValueCounter, inputInteger, row, column) => {
        if (newValueCounter >= 0 && (/^\d+$/.test(inputInteger))) {
            return {
                ...row,
                [column.id]: newValueCounter.toString(),
                isPortValueSingleOrPair: Constants.PORT_VALUES_TYPES.SINGLE
            };
        } else if (HelperFunctions.extractPortFromOsp(inputInteger)) {
            return {
                ...row,
                [column.id]: HelperFunctions.incrementAndFormat(newValueCounter),
                isPortValueSingleOrPair: Constants.PORT_VALUES_TYPES.PAIR
            };
        }
        return { ...row, [column.id]: "" };
    }

    static alphanumericSort = (a, b) => a.localeCompare(b, undefined, {
        numeric: true,
        sensitivity: "base"
    })

    static alphanumericSortList = list => list.sort((a, b) =>
        a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" }))

    static getAttributeValueFromLink = (link, attributeKey) => {
        const matchingAttribute = link.attributes.filter(attribute => attribute.key === attributeKey).find(Boolean);
        if (!matchingAttribute) {
            return null;
        }

        return HelperFunctions.getAttributeValue(matchingAttribute);
    }

    static getAttributeValue = (attribute) => {
        // Check to see if the attribute and/or its value is defined
        if (!attribute || !attribute.value || !attribute.value.type) {
            return null;
        }

        // Go through the different types and fetch the relevant attribute value
        if (attribute.value.type === Constants.ATTRIBUTES_TYPES.boolean) {
            return attribute.value.booleanValue;
        }
        if (attribute.value.type === Constants.ATTRIBUTES_TYPES.integer) {
            return attribute.value.integerValue;
        }
        return attribute.value.stringValue;
    }

    static getFullLinkInstanceIdFromLink = (link) => {
        if (!link || !link.instanceId) {
            return null;
        }

        const { instanceId } = link;
        return HelperFunctions.createFullLinkInstanceId(instanceId);
    }

    static createFullLinkInstanceId = (instanceId) => {
        if (!instanceId) {
            return null;
        }

        if (instanceId.startsWith(Constants.LINK_INSTANCE_ID_PATTERN)) {
            return instanceId;
        }

        return Constants.LINK_INSTANCE_ID_PATTERN + instanceId;
    }

    static findDuplicates = (list) => {
        if (!list || list.length === 0) {
            return [];
        }

        return list.filter((e, i, a) => a.indexOf(e) !== i);
    }

    /**
     * Null safe and type safe method to check if an array is empty or not
     * @param array
     * @returns {boolean}
     */
    static isArrayEmpty = (array) => {
        // Check to ensure that we have an array object and that its not empty (length > 0)
        if (!Array.isArray(array) || !array.length) {
            return true;
        }

        return false;
    }

    static createStringAttribute = (key, value) => ({
        key,
        value: {
            type: Constants.ATTRIBUTES_TYPES.string,
            booleanValue: false,
            integerValue: 0,
            stringValue: value
        }
    })

    static getConsumerIdsFromAttribute = link =>
        HelperFunctions.#getListValueFromAttribute(link, Constants.CONSUMPTION_ATTRIBUTES.consumerList)

    static getConsumedIdsFromAttribute = link =>
        HelperFunctions.#getListValueFromAttribute(link, Constants.CONSUMPTION_ATTRIBUTES.consumesList)

    static #getListValueFromAttribute = (link, attributeKey) => {
        if (!link || !link.attributes) {
            return [];
        }

        const matchingAttribute = link.attributes
            .filter(attribute => attribute.key === attributeKey)
            .find(Boolean);
        const matchingAttributeValue = HelperFunctions.getAttributeValue(matchingAttribute);

        if (!matchingAttributeValue) {
            return [];
        }

        return JSON.parse(matchingAttributeValue);
    }

    static strFormat = (str, ...args) => args.reduce((s, v) => s.replace("%s", v), str);
}
